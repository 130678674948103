import { template as template_24f09b108d4e47bb9e952a77fa3218aa } from "@ember/template-compiler";
const FKControlMenuContainer = template_24f09b108d4e47bb9e952a77fa3218aa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
