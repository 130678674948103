import { template as template_8a7986bbddfa43c49348354d5c65b79d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8a7986bbddfa43c49348354d5c65b79d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
