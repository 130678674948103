import { template as template_8c7b24420a0d426a9670b2fe3d0cf367 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8c7b24420a0d426a9670b2fe3d0cf367(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
