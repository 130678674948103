import { template as template_89c49ff2425e4564b41ab99985a1380c } from "@ember/template-compiler";
const WelcomeHeader = template_89c49ff2425e4564b41ab99985a1380c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
