import { template as template_877891ebcb0548718b95f87f2097e7e0 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_877891ebcb0548718b95f87f2097e7e0(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
